<template>
    <svg
        width="200"
        height="200"
        viewBox="0 0 200 200"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="100"
            cy="100"
            r="99.5"
            stroke="#8950C0"
            stroke-dasharray="5 5"
        />
        <circle cx="100" cy="100" r="86.9565" fill="url(#paint0_linear_1_51)" />
        <path
            d="M119.098 88.5721C133.217 89.0271 144.616 100.657 144.757 114.729C144.804 119.881 143.373 124.692 140.861 128.78C140.505 129.356 140.438 130.058 140.653 130.694L143.118 138.055C143.964 140.591 141.439 142.953 138.953 141.949L131.343 138.871C130.752 138.63 130.087 138.637 129.516 138.904C126.124 140.497 122.342 141.4 118.352 141.414C104.052 141.481 92.1292 130.018 91.7127 115.779C91.2627 100.53 103.79 88.0769 119.105 88.5654L119.098 88.5721Z"
            fill="url(#paint1_linear_1_51)"
        />
        <g filter="url(#filter0_b_1_51)">
            <path
                d="M89.7032 60.888C71.441 61.4757 56.7034 76.6087 56.5235 94.9206C56.4568 101.626 58.309 107.89 61.5604 113.199C62.0201 113.947 62.1067 114.862 61.8336 115.697L58.6488 125.28C57.5495 128.579 60.8142 131.651 64.0322 130.342L73.8795 126.342C74.6391 126.035 75.4986 126.035 76.2448 126.389C80.6288 128.459 85.5191 129.634 90.6826 129.655C109.178 129.735 124.595 114.829 125.135 96.2964C125.715 76.4484 109.518 60.2468 89.7099 60.888H89.7032Z"
                fill="url(#paint2_linear_1_51)"
            />
            <path
                d="M57.0234 94.9256L57.0234 94.9256C57.2007 76.8827 71.7222 61.9712 89.7113 61.388H89.718L89.7261 61.3877C109.244 60.756 125.206 76.7205 124.635 96.2818C124.103 114.543 108.909 129.234 90.6848 129.155H90.6846C85.5967 129.135 80.7785 127.977 76.4588 125.937C75.5767 125.519 74.571 125.523 73.6921 125.879L73.6914 125.879L63.844 129.879L63.8438 129.879C61.0239 131.026 58.158 128.335 59.1232 125.438L59.1233 125.438L62.3081 115.855L62.3088 115.852C62.6247 114.887 62.5277 113.818 61.9865 112.938C58.7829 107.706 56.9578 101.534 57.0234 94.9256Z"
                stroke="url(#paint3_linear_1_51)"
            />
        </g>
        <defs>
            <filter
                id="filter0_b_1_51"
                x="38.5217"
                y="42.8696"
                width="104.628"
                height="105.782"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="9" />
                <feComposite
                    in2="SourceAlpha"
                    operator="in"
                    result="effect1_backgroundBlur_1_51"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_backgroundBlur_1_51"
                    result="shape"
                />
            </filter>
            <linearGradient
                id="paint0_linear_1_51"
                x1="221.739"
                y1="334.783"
                x2="78.2609"
                y2="7.60873"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#F02247" />
                <stop offset="1" stop-color="#F02247" stop-opacity="0" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_1_51"
                x1="105.516"
                y1="88.5515"
                x2="155.971"
                y2="105.975"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#F02247" />
                <stop offset="1" stop-color="#231035" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_1_51"
                x1="60.3962"
                y1="126.712"
                x2="122.29"
                y2="65.8408"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="white" stop-opacity="0.2" />
                <stop offset="1" stop-color="white" stop-opacity="0.49" />
            </linearGradient>
            <linearGradient
                id="paint3_linear_1_51"
                x1="61.3661"
                y1="66.3578"
                x2="119.754"
                y2="127.756"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="white" />
                <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
        </defs>
    </svg>
</template>
